<template>
  <div class="home d-flex align-items-center justify-content-center">
    <img alt="Vue logo" src="@/assets/djms_logo_bl.svg" class="vertical-center main-logo">
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
