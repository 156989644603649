<template>
  <div id="main">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
    </div> -->
    <router-view/>
  </div>
</template>

<script>
export default {
    name: 'App',    
    // metaInfo: {
    //   title: "DJMS - Cloud Solution Company",
    //   meta: [
    //       { name: 'description', content:  'A DJMS tao-ja segíteni a kisebb és nagyobb cégeket a Digitális Átállás technikai útvesztőjében eligazodni.'},
    //       { property: 'og:title', content: "DJMS - Cloud Solution Company"},
    //       { property: 'og:site_name', content: 'DJMS.hu'},
    //       { property: 'og:type', content: 'website'},
    //       { property: 'og:image', content: 'https://djms.hu/img/bg_and_white.png'},
    //       { name: 'robots', content: 'index,follow'} 
    //   ]
    // }
}
</script>

<style lang="scss">
@import '@/style/index.scss';

</style>
