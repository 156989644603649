import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
// import VueMeta from 'vue-3-meta'
createApp(App)
  .use(store)
  .use(router)
  // .use(VueMeta)
  .mount('#app')
